<template>
  <a-card>
    <a-form-model
        ref="search_form"
        :model="search_form"
        layout="inline"
        :rules="rules"
    >
      <a-row style="padding-bottom: 12px">
        <a-col :span="20">
          <span class="title-top">任务信息</span>
        </a-col>
        <a-col :span="4">
          <div v-if="isShowItem">
            <a-button
                class="mr-10"
                @click="SubmitButtons(1)"
            >取消
            </a-button>
            <a-button
                type="primary"
                @click="SubmitButtons(2)"
            >确定
            </a-button>
          </div>
          <a-button
              type="primary"
              @click="isShowItemClick"
              v-else
              :disabled="search_form.statusName == '已完成'"
          >
            编辑
          </a-button>
        </a-col>
      </a-row>
      <div v-if="type == 'script'">
        <a-row style="padding-bottom: 12px">
          <a-col :span="7">
            <a-form-model-item label="编导" prop="directorId">
              <a-select
                  v-model="search_form.directorIdList"
                  allow-clear
                  label-in-value
                  placeholder="请选择"
                  style="width: 230px"
                  @change="changeDirectorId"
                  v-if="isShowItem"
              >
                <a-select-option :value="item.id" v-for="item in directorIdList" :key="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
              <span v-else>{{ search_form.directorName || "" }}</span>
            </a-form-model-item>
          </a-col>
          <a-col :span="7">
            <a-form-model-item label="脚本类型">
              <a-input
                  style="width: 230px"
                  placeholder="请输入"
                  v-model="search_form.scriptType"
                  v-if="isShowItem"
              />
              <span v-else>{{ search_form.scriptType || "" }}</span>
            </a-form-model-item>
          </a-col>
          <a-col :span="7">
            <a-form-model-item label="脚本难度">
              <a-select
                  v-model="search_form.difficulty"
                  allow-clear
                  placeholder="请选择"
                  style="width: 230px"
                  v-if="isShowItem"
              >
                <a-select-option :value="item.id" v-for="item in difficultyList" :key="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
              <span v-else>
              {{
                  search_form.difficulty == 1 ? "高" : search_form.difficulty == 2 ? "中" : search_form.difficulty == 3 ? "低" : ""
                }}</span>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row style="padding-bottom: 12px">
          <a-col :span="7">
            <a-form-model-item label="状 态" prop="status">
              <a-select
                  v-model="search_form.status"
                  allow-clear
                  placeholder="请选择"
                  style="width: 230px"
                  v-if="isShowItem"
              >
                <a-select-option :value="item.id" v-for="item in statusList" :key="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
              <span v-else>{{ search_form.statusName || "" }}</span>
            </a-form-model-item>
          </a-col>
          <a-col :span="7">
            <a-form-model-item label="车系">
              <a-select
                  v-model="search_form.seriesName"
                  allow-clear
                  show-search
                  placeholder="请选择"
                  style="width: 260px"
                  :filter-option="filterOption"
                  v-if="isShowItem"
              >
                <a-select-option value="0">
                  不限
                </a-select-option>
                <a-select-option :value="item.name" v-for="item in seriesId_list" :key="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
              <span v-else>{{ search_form.seriesName || "" }}</span>
            </a-form-model-item>
          </a-col>
          <a-col :span="7">
            <a-form-model-item
                label="脚本日期"
            >
              <!--<a-range-picker-->
              <!--    :placeholder="['开始日期', '结束日期']"-->
              <!--    format="YYYY-MM-DD"-->
              <!--    inputReadOnly-->
              <!--    @change="onChangeDate(arguments, 'scriptSubmit')"-->
              <!--    style="width: 230px"-->
              <!--    v-model="time"-->
              <!--    v-if="isShowItem"-->
              <!--/>-->
              <span>{{ search_form.scriptDate || "" }}</span>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row style="padding-bottom: 12px">
          <a-col :span="7">
            <a-form-model-item
                label="完成日期"
            >
              <span>{{ search_form.finishDate || "" }}</span>
            </a-form-model-item>
          </a-col>
          <a-col :span="7">
            <a-form-model-item
                label="创建日期"
            >
              <span>{{ search_form.ctime || "" }}</span>
            </a-form-model-item>
          </a-col>
        </a-row>
      </div>

      <div v-if="type == 'product'">
        <a-row style="padding-bottom: 12px">
          <a-col :span="7">
            <a-form-model-item label="状 态" prop="status">
              <a-select
                  v-model="search_form.status"
                  allow-clear
                  placeholder="请选择"
                  style="width: 230px"
                  v-if="isShowItem"
              >
                <a-select-option :value="item.id" v-for="item in statusList" :key="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
              <span v-else>{{ search_form.statusName || "" }}</span>
            </a-form-model-item>
          </a-col>
          <a-col :span="7">
            <a-form-model-item label="摄影" prop="photographyIds">
              <a-select
                  mode="multiple"
                  v-model="search_form.photographyIds"
                  allow-clear
                  show-search
                  :filter-option="filterOption"
                  placeholder="请选择"
                  style="width: 230px"
                  v-if="isShowItem"
              >
                <a-select-option :value="item.id" v-for="(item, index) in directorIdList" :key="`${item.id}-${index}`">
                  {{ item.name }}
                </a-select-option>
              </a-select>
              <span v-else>{{ search_form.photographyName || "" }}</span>
            </a-form-model-item>
          </a-col>
          <a-col :span="7">
            <a-form-model-item
                label="摄影日期"
            >
              <a-date-picker
                  :placeholder="'请选择'"
                  format="YYYY-MM-DD"
                  value-format="YYYY-MM-DD"
                  style="width: 230px"
                  v-model="search_form.photoDate"
                  v-if="isShowItem"
              />
              <span v-else>{{ search_form.photoDate || "" }}</span>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row style="padding-bottom: 12px">
          <a-col :span="7">
            <a-form-model-item label="成片类型">
              <a-input
                  style="width: 230px"
                  placeholder="请输入"
                  v-model="search_form.productType"
                  v-if="isShowItem"
              />
              <span v-else>{{ search_form.productType || "" }}</span>
            </a-form-model-item>
          </a-col>
          <a-col :span="7">
            <a-form-model-item label="剪辑">
              <a-select
                  v-model="search_form.editorId"
                  allow-clear
                  show-search
                  :filter-option="filterOption"
                  placeholder="请选择"
                  style="width: 230px"
                  v-if="isShowItem"
              >
                <a-select-option :value="item.id" v-for="(item, index) in directorIdList" :key="`${item.id}-${index}`">
                  {{ item.name }}
                </a-select-option>
              </a-select>
              <span v-else>{{ search_form.editorName || "" }}</span>
            </a-form-model-item>
          </a-col>
          <a-col :span="7">
            <a-form-model-item label="剪辑日期">
              <span>{{ search_form.editDate || "" }}</span>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row style="padding-bottom: 12px">
          <a-col :span="7">
            <a-form-model-item label="脚本">
              <span style="cursor: pointer;color: #40a9ff" @click="SubmitButtons(3)">{{
                  search_form.scriptName || ""
                }}</span>
            </a-form-model-item>
          </a-col>
          <a-col :span="7">
            <a-form-model-item label="车系">
              <span>{{ search_form.seriesName || "" }}</span>
            </a-form-model-item>
          </a-col>
          <a-form-model-item label="摄影场地">
            <a-input
                placeholder="请输入"
                v-model="search_form.photoAddr"
                style="width: 260px;"
                v-if="isShowItem"
            />
            <span v-else>{{ search_form.photoAddr || "" }}</span>
          </a-form-model-item>
        </a-row>
        <a-row style="padding-bottom: 12px">
          <a-col :span="7">
            <a-form-model-item
                label="摄影批注"
            >
              <vod-base-upload
                  :accept-list="'.png,.jpg,.jpeg'"
                  :upload-title="''"
                  :has-file-list="photography_list"
                  :params-data="null"
                  :client-id="'bfz-admin'"
                  @handleUrl="handleUrl(arguments, 'photography')"
                  @handlePreview="handlePreviewOpen(arguments)"
                  v-if="isShowItem"
              />
              <!--<v-upload-->
              <!--    :accept-list="'.png,.jpg,.jpeg'"-->
              <!--    :has-file-list="photography_list"-->
              <!--    :suffix-list="['png','jpg','jpeg']"-->
              <!--    :limit-size="2"-->
              <!--    :is-open-preview="true"-->
              <!--    @handleUrl="handleUrl(arguments, 'photography')"-->
              <!--    @handlePreview="handlePreviewOpen(arguments)"-->
              <!--    v-if="isShowItem"-->
              <!--/>-->
              <div v-else class="url-img" @click="openRemarkUrl">
                <img v-if="search_form.remarkUrl" :src="search_form.remarkUrl" alt="摄影批注">
                <a-icon v-else type="picture" style="font-size: 52px;padding-left: 6px"/>
              </div>
            </a-form-model-item>
          </a-col>
          <a-col :span="7">
            <a-form-model-item label="完成日期">
              <span>{{ search_form.finishDate || "" }}</span>
            </a-form-model-item>
          </a-col>
          <a-col :span="7">
            <a-form-model-item label="创建日期">
              <span>{{ search_form.ctime || "" }}</span>
            </a-form-model-item>
          </a-col>
        </a-row>
      </div>

      <div v-if="type == 'template'">
        <a-row style="padding-bottom: 12px">
          <a-col :span="7">
            <a-form-model-item label="状 态" prop="status">
              <a-select
                  v-model="search_form.status"
                  allow-clear
                  placeholder="请选择"
                  style="width: 230px"
                  v-if="isShowItem"
              >
                <a-select-option :value="item.id" v-for="item in statusList" :key="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
              <span v-else>{{ search_form.statusName || "" }}</span>
            </a-form-model-item>
          </a-col>
          <a-col :span="7">
            <a-form-model-item label="模板名称" prop="guideshootName">
              <a-input
                  :maxLength="30"
                  v-if="isShowItem"
                  v-model="search_form.guideshootName"
              />
              <span v-else>{{ search_form.guideshootName || "" }}</span>
            </a-form-model-item>
          </a-col>
          <a-col :span="7">
            <a-form-model-item
                label="模板ID"
            >
              <span>{{ search_form.fhlGuideshootId || "" }}</span>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row style="padding-bottom: 12px">
          <a-col :span="7">
            <a-form-model-item label="上架状态">
              <span>{{
                  search_form.fhlGuideshootStatus == 1 ? "已上架" : search_form.fhlGuideshootStatus == 2 ? "未上架" : ""
                }}</span>
            </a-form-model-item>
          </a-col>
          <a-col :span="7">
            <a-form-model-item label="完成日期">
              <span>{{ search_form.releaseDate || "" }}</span>
            </a-form-model-item>
          </a-col>
          <a-col :span="7">
            <a-form-model-item
                label="创建日期"
            >
              <span>{{ search_form.ctime || "" }}</span>
            </a-form-model-item>
          </a-col>
        </a-row>
      </div>
    </a-form-model>
    <div class="table-box">
      <tableDetail
          :columns="columns"
          :list="list"
          :title="'脚本信息'"
          :table-name="'script'"
          :button-name="'上传脚本'"
          :biz-id="bizId"
          :biz-type="type"
          :loading="loading"
          :list-length="listLength"
          :status="search_form.statusName"
          @loaded="loaded"
          v-if="type == 'script'"
      />
      <tableDetail
          :columns="product_columns"
          :list="list"
          :title="'成片信息'"
          :table-name="'product'"
          :button-name="'上传成片'"
          :biz-id="bizId"
          :biz-type="type"
          :loading="loading"
          :list-length="listLength"
          :status="search_form.statusName"
          @loaded="loaded"
          v-if="type == 'product'"
      />
      <template v-if="type == 'template'">
        <tableDetail
            :columns="group_columns"
            :list="groupList"
            :title="'可见分组'"
            :table-name="'group'"
            :button-name="'添加'"
            :biz-id="bizId"
            :biz-type="type"
            :loading="loading"
            :list-length="listLength"
            :keys="keys"
            :status="search_form.statusName"
            @loaded="loaded"

        />
        <tableDetail
            :columns="test_columns"
            :list="testList"
            :title="'更新记录'"
            :table-name="'test'"
            :biz-id="bizId"
            :biz-type="type"
            :loading="loading"
            :list-length="listLength"
            :status="search_form.statusName"
            @loaded="loaded"
        />
      </template>
      <tableDetail
          :columns="suggestion_columns"
          :list="suggestion_list"
          :title="'修改意见'"
          :table-name="'suggestion'"
          :button-name="'添加意见'"
          :biz-id="bizId"
          :biz-type="type"
          :loading="suggestion_loading"
          :status="search_form.statusName"
          @loaded="loaded"
          v-if="type == 'script' || type == 'product'"
      />
      <tableDetail
          :columns="process_columns"
          :list="process_list"
          :title="'流转记录'"
          :table-name="'process'"
          :button-name="''"
          :biz-id="bizId"
          :biz-type="type"
          :loading="process_loading"
          :status="search_form.statusName"
          @loaded="loaded"
      />
    </div>
    <div class="template_box">
      <a-button
          @click="goBlack"
          type="primary"
          class="mr-10"
      >返回
      </a-button>
    </div>


    <!--查看成片-->
    <preview-modal
        :visible="preview_visible"
        :preview-src="preview_src"
        width="80%"
        @cancel="handlePreviewCancel"
    />
  </a-card>
</template>

<script>
import _api from "@/api/contentProduction.js";
import { Tool } from "@/utils";
import { isEqual } from "lodash";
import tableDetail from "./components/table_detail";
import { cloneDeep } from "lodash-es";
import previewModal from "@/components/PreviewModalVideo/PreviewModalVideo";
import vodBaseUpload from "@/components/VodBaseUpload/vodBaseUpload";
import utils from "@/common/util.js";


export default {
  name: "TaskManagementDetail",
  data() {
    return {
      taskId: this.$route.query.taskId,
      type: this.$route.query.type,
      statusList: [],
      search_form: {},
      rules: {
        directorId: [{required: true, message: "请选择编导", trigger: "change"}],
        status: [{required: true, message: "请选择状态", trigger: "change"}],
        guideshootName: [{required: true, message: "请输入模板名称", trigger: "blur"}],
        photographyIds: [
          {
            type: "array",
            required: true,
            validator: (rule, value, callback) => {
              if(!value.length) {
                callback("请选择摄影");
                return;
              }
              callback();
            }
          }
        ],
      },
      directorIdList: [],
      seriesId_list: [],
      difficultyList: [
        {
          id: 1,
          name: "高"
        },
        {
          id: 2,
          name: "中"
        },
        {
          id: 3,
          name: "低"
        },
      ],
      isShowItem: false,
      time: [],
      data: {},
      photographyList: [],
      editorList: [],
      columns: [
        {
          scopedSlots: {customRender: "names"},
          title: "脚本名称",
          width: 200,
          ellipsis: true,
        },
        {
          scopedSlots: {customRender: "version"},
          title: "成片版本"
        },
        {
          dataIndex: "creatorName",
          title: "上传人"
        },
        {
          dataIndex: "ctime",
          title: "上传时间"
        },
      ],
      loading: false,
      list: [],
      suggestion_columns: [
        {
          dataIndex: "source",
          title: "意见来源",
          ellipsis: true,
        },
        {
          dataIndex: "suggestion",
          title: "意见内容",
          width: 120,
          ellipsis: true,
        },
        {
          dataIndex: "creatorName",
          title: "添加人"
        },
        {
          dataIndex: "ctime",
          title: "添加时间"
        },
      ],
      suggestion_loading: false,
      suggestion_list: [],
      process_columns: [
        {
          dataIndex: "processAction",
          title: "状态变更",
          ellipsis: true,
        },
        {
          dataIndex: "creatorName",
          title: "操作人"
        },
        {
          dataIndex: "ctime",
          title: "操作时间"
        },
      ],
      process_loading: false,
      process_list: [],
      bizId: "",
      listLength: 0,
      product_columns: [
        {
          scopedSlots: {customRender: "product_version"},
          title: "成片版本"
        },
        {
          dataIndex: "attachSize",
          scopedSlots: {customRender: "attachSize"},
          title: "文件大小"
        },
        {
          dataIndex: "creatorName",
          title: "上传人"
        },
        {
          dataIndex: "ctime",
          title: "上传时间"
        },
      ],
      group_columns: [
        {
          dataIndex: "group_name",
          title: "分组名称"
        },
        {
          dataIndex: "manufactor_dealer_name",
          title: "可见对象",
          width: 260,
          ellipsis: true,
        },
        {
          dataIndex: "ctime",
          title: "添加时间"
        },
        {
          scopedSlots: {customRender: "operation"},
          title: "操作",
        },
      ],
      test_columns: [
        {
          dataIndex: "ctime",
          width: 180,
          title: "上传时间"
        },
        {
          dataIndex: "creatorName",
          title: "上传"
        },
        {
          dataIndex: "atime",
          width: 180,
          title: "测试时间"
        },
        {
          dataIndex: "actorName",
          title: "测试"
        },
        {
          dataIndex: "isPass",
          scopedSlots: {customRender: "isPass"},
          title: "测试结果"
        },
        {
          dataIndex: "problemType",
          title: "问题类型"
        },
        {
          dataIndex: "problemDescription",
          title: "问题描述"
        },
        {
          scopedSlots: {customRender: "operation"},
          title: "测试记录",
        },
      ],
      groupList: [],
      testList: [],
      backup: {},
      keys: [],
      preview_visible: false,
      preview_src: "",
      suffix_list: ["png", "jpg", "jpeg"],
      thumb_url: "http://afanticar-test.oss-cn-hangzhou.aliyuncs.com/adsys/2020/10/xQVHkI8uIrzoICsANthwzqqhZtAUUUmIqUr0uJiX.jpeg",
      photography_list: []
    };
  },
  components: {tableDetail, previewModal, vodBaseUpload},
  mounted() {
    switch(this.type) {
      case "script":
        this.statusList = [
          {
            color: "#ff4446",
            name: "待分配",
            id: 1
          },
          {
            color: "#2A90F4",
            name: "策划中",
            id: 2
          },
          {
            color: "#2A90F4",
            name: "内审中",
            id: 3
          },
          {
            color: "#2A90F4",
            name: "修改中",
            id: 4
          },
          {
            color: "#ff4446",
            name: "待定稿",
            id: 5
          },
          {
            color: "#0bc9b9",
            name: "已完成",
            id: 6
          },
          {
            color: "#666",
            name: "备用/待定",
            id: 7
          },
          {
            color: "#666",
            name: "内审退回",
            id: 8
          },
          {
            color: "#666",
            name: "客审退回",
            id: 9
          },
        ];
        break;
      case "product":
        this.statusList = [
          {
            color: "#ff4446",
            name: "待分配",
            id: 1
          },
          {
            color: "#2A90F4",
            name: "拍摄中",
            id: 2
          },
          {
            color: "#ff4446",
            name: "待剪辑",
            id: 3
          },
          {
            color: "#2A90F4",
            name: "剪辑中",
            id: 4
          },
          {
            color: "#2A90F4",
            name: "修改中",
            id: 5
          },
          {
            color: "#2A90F4",
            name: "内审中",
            id: 6
          },
          {
            color: "#ff4446",
            name: "待审核",
            id: 7
          },
          {
            color: "#0bc9b9",
            name: "已完成",
            id: 8
          },
          {
            color: "#666",
            name: "客审退回",
            id: 9
          },
        ];
        break;
      case "template":
        this.statusList = [
          {
            color: "#ff4446",
            name: "待上传",
            id: 1
          },
          {
            color: "#ff4446",
            name: "待调整",
            id: 2
          },
          {
            color: "#2A90F4",
            name: "测试中",
            id: 3
          },
          {
            color: "#ff4446",
            name: "待上线",
            id: 4
          },
          {
            color: "#0bc9b9",
            name: "已完成",
            id: 5
          },
          {
            color: "#666",
            name: "暂不上线",
            id: 6
          },
        ];
        break;
    }
    this.getAuthUsers();
    if(this.taskId) {
      this.getTaskDetail();
    }
  },
  methods: {
    getAttachList() {
      this.loading = true;
      const params = {
        bizId: this.bizId
      };
      _api.getAttachList(params).then(res => {
        if(res.code == 200) {
          this.list = (res.data || []).map(item => {
            item.size = item.attachSize ? Tool.getfilesize(item.attachSize) : 0;
            item.script_name = item.attachName.split(".")[0];
            return item;
          });
          this.listLength = res.data.length || 0;
          console.log(this.listLength);
        } else {
          this.$message.error(res.message || "获取数据失败");
        }
        this.loading = false;
      });
    }, // 脚本/成片信息列表
    getSuggestionList() {
      this.suggestion_loading = true;
      const params = {
        bizId: this.bizId
      };
      _api.getSuggestionList(params).then(res => {
        if(res.code == 200) {
          this.suggestion_list = res.data || [];
        } else {
          this.$message.error(res.message || "获取数据失败");
        }
        this.suggestion_loading = false;
      });
    }, // 意见列表
    getTaskDetail() {
      this.process_loading = true;
      this.loading = true;
      _api.getTaskDetail(this.taskId, this.type).then(res => {
        if(res.code == 200) {
          this.search_form = res.data || {};
          this.process_list = res.data.processList || [];
          this.groupList = res.data.groupList || [];
          this.testList = res.data.testList || [];
          this.data = res.data || {};
          this.bizId = res.data.id || "";
          this.keys = res.data.id ? [res.data.id] : [];
          this.principalId = res.data.principalId || "";
          if(!res.data.photographyIds) {
            this.search_form.photographyIds = [];
          }
          if(!res.data.difficulty) {
            this.search_form.difficulty = undefined;
          }
          this.$set(this.search_form, "directorIdList", {
            key: res.data.directorId,
            label: res.data.directorName,
          });
          this.$set(this.search_form, "remarkUrl", res.data.remarkUrl);
          this.photography_list = this.hasFileArr(this.search_form.remarkUrl, "photography");
          this.getSeriesIdList();
          if(this.type == "script" || this.type == "product") {
            this.getAttachList();
            this.getSuggestionList();
          }
        } else {
          this.$message.error(res.message || "获取详情数据失败");
        }
        this.process_loading = false;
        this.loading = false;
      });
    }, // 脚本/成片详情
    getSeriesIdList() {
      _api.getSeriesIdList({principalId: this.principalId}).then(res => {
        if(res.code == 200) {
          this.seriesId_list = res.data || [];
        } else {
          this.seriesId_list = [];
          this.$message.error(res.message || "获取车系下拉数据失败");
        }
      });
    }, // 车系列表
    getAuthUsers() {
      const params = {
        page_no: 1,
        page_size: 999
      };
      _api.getAuthUsers(params).then(res => {
        if(res.code == 200) {
          console.log(res.data.list);
          this.directorIdList = res.data.list || [];
        } else {
          this.directorIdList = [];
          this.$message.error(res.message || "获取编导下拉数据失败");
        }
      });
    }, // 编导下拉数据
    changeDirectorId(val) {
      this.search_form.directorIdList = val;
      this.search_form.directorId = val.key;
      this.search_form.directorName = val.label;
    },// 编导选择
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    }, // 过滤
    onChangeDate(dates, type) {
      let start_time = "";
      let end_time = "";
      const timeArray = dates[1];
      if(timeArray) {
        start_time = timeArray[0] ? this.$moment(timeArray[0]).format("YYYY-MM-DD") : "";
        end_time = timeArray[1] ? this.$moment(timeArray[1]).format("YYYY-MM-DD") : "";
      }
      switch(type) {
        case "scriptSubmit":
          this.search_form.scriptDateBegin = start_time;
          this.search_form.scriptDateEnd = end_time;
          break;
        case "uploadDate":
          this.search_form.uploadDateStart = start_time;
          this.search_form.uploadDateEnd = end_time;
          break;
        case "photoDate":
          this.search_form.photoDateBegin = start_time;
          this.search_form.photoDateEnd = end_time;
          break;
        case "testDate":
          this.search_form.testDateStart = start_time;
          this.search_form.testDateEnd = end_time;
          break;
        case "releaseDate":
          this.search_form.releaseDateStart = start_time;
          this.search_form.releaseDateEnd = end_time;
          break;
        case "editDate":
          this.search_form.editDateBegin = start_time;
          this.search_form.editDateEnd = end_time;
          break;
      }
    }, // 日期选择框
    //搜索
    SubmitButtons(type) {
      switch(type) {
        case 1:
          const that = this;
          this.$confirm({
            title: "确定取消编辑吗？",
            content: "取消后，修改内容将会丢失！",
            okText: "确定",
            cancelText: "取消",
            onOk: () => {
              that.isShowItem = false;
              this.search_form = cloneDeep(this.backup);
            },
            onCancel() {
              // console.log("取消");
            },
          });
          break;
        case 2:
          let params = {};
          let Api = "";
          this.photographyList = this.photographyList.concat(this.editorList);
          const {photographyIds, editorId} = this.search_form;
          const workerInfo = [
            ...(photographyIds?.map(workerId => ({
              workerId,
              workerType: 1,
              workerName: this.directorIdList.find(v => isEqual(workerId, v.id))?.name
            })) || []),
            ...(editorId ? [editorId].map(workerId => ({
              workerId,
              workerType: 2,
              workerName: this.directorIdList.find(v => isEqual(workerId, v.id))?.name
            })) : [])
          ];
          switch(this.type) {
            case "script":
              Api = _api.postOrderScriptTask;
              params = {
                id: this.search_form.id,
                // orderId: this.search_form.orderId,
                directorId: this.search_form.directorId,
                directorName: this.search_form.directorName,
                scriptType: this.search_form.scriptType || "",
                difficulty: this.search_form.difficulty || 0,
                seriesName: this.search_form.seriesName || "",
                status: this.search_form.status,
              };
              break;
            case "product":
              Api = _api.postOrderProductTask;
              params = {
                id: this.search_form.id,
                photoDate: this.search_form.photoDate || "",
                productType: this.search_form.productType || "",
                status: this.search_form.status,
                remarkUrl: this.search_form.remarkUrl,
                photoAddr: this.search_form.photoAddr,
                workerInfo
              };
              break;
            case "template":
              Api = _api.postOrderTemplateTask;
              params = {
                id: this.search_form.id,
                guideshootName: this.search_form.guideshootName || "",
                status: this.search_form.status,
              };
              break;
          }
          this.uploadTaskDetail(params, Api);
          break;
        case 3:
          if(this.search_form.scriptAttachUrl) {
            let str = this.search_form.scriptAttachUrl.split("?")[0];
            const isWX = str.split("/")[2];
            const isImg = str.split(".")[3];
            if(isImg && isImg == "webp") {
              this.preview_visible = true;
              this.preview_src = this.search_form.scriptAttachUrl || "";
            } else if(isWX && isWX == "doc.weixin.qq.com") {
              window.open(this.search_form.scriptAttachUrl, "_target");
            } else {
              let URL = encodeURIComponent(this.search_form.scriptAttachUrl);
              window.open(`http://view.officeapps.live.com/op/view.aspx?src=${ URL }`, "_target");
            }
          } else {
            this.$message.error("当前脚本无法查看");
          }
          break;
      }
    },
    uploadTaskDetail(params, Api) {
      this.$refs.search_form.validate((valid) => {
        if(!valid) {
          return;
        }
        Api(params).then(res => {
          if(res.code == 200) {
            this.isShowItem = false;
            this.getTaskDetail();
            this.getAttachList();
            this.$message.success("更新信息成功");
          } else {
            this.$message.error(res.message || "更新任务信息失败");
          }
        });
      });
    }, // 修改
    loaded(type) {
      console.log(type);
      switch(type) {
        case "script":
          this.getAttachList();
          this.getTaskDetail();
          break;
        case "product":
          this.getAttachList();
          break;
        case "suggestion":
          this.getSuggestionList();
          break;
        case "group":
          this.getTaskDetail();
          break;
      }
    },
    isShowItemClick() {
      this.isShowItem = true;
      this.backup = cloneDeep(this.search_form);
    },
    goBlack() {
      this.$router.go(-1);
    },
    handleUrl(data, type) {
      let res = data[0];
      let url = !res ? "" : res.xhr;
      console.log(url);
      let file_arr = this.hasFileArr(url, type);
      this.$set(this.search_form, "remarkUrl", url);
      this.photography_list = file_arr;
    },
    hasFileArr(url, type) {
      this.thumb_url = "http://fhl-ai-video-test.oss-cn-hangzhou.aliyuncs.com/image/2021-8-9/doc.png";
      if(url) {
        return [
          {
            uid: "-1",
            name: type,
            status: "done",
            url: url,
            thumbUrl: utils.fileSuffixTypeUtil(url, this.suffix_list) ? this.thumb_url : url,
            editor: true
          }
        ];
      } else {
        return [];
      }
    },
    handlePreviewOpen(data) {
      this.preview_visible = true;
      this.preview_src = data[0];
    }, //预览
    handlePreviewCancel() {
      this.preview_src = "";
      this.preview_visible = false;
    },  //关闭预览
    openRemarkUrl() {
      if(this.search_form.remarkUrl) {
        this.preview_visible = true;
        this.preview_src = this.search_form.remarkUrl;
      } else {
        this.$message.error("URL为空，不支持查看！");
      }
    },
  }
};
</script>

<style scoped lang="less">
.mr-10 {
  margin-right: 15px;
}

.title-top {
  font-size: 16px;
  font-weight: bold;
}

.template_box {
  padding-top: 60px;
  display: flex;
  justify-content: flex-end;
}

.url-img {
  width: 88px;
  height: 88px;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
  }
}
</style>
