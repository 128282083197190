<template>
  <div>
    <a-modal :visible="visible" :title="title" @ok="handleOk" @cancel="handleCancel" width="70%">
      <a-form-model
          ref="formRef"
          :model="groupModel"
          layout="inline"
      >
        <a-row style="padding-bottom: 12px">
          <a-col :span="9">
            <a-form-model-item label="分组">
              <a-input
                  v-model="groupModel.group_name"
                  placeholder="请输入分组名称"
                  style="width: 200px"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="9">
            <a-form-model-item
                label="目标可见"
            >
              <a-select v-model="groupModel.open_type" placeholder="请选择目标" style="width: 200px">
                <a-select-option value="0">
                  全部
                </a-select-option>
                <a-select-option value="1">
                  品牌
                </a-select-option>
                <a-select-option value="2">
                  经销商
                </a-select-option>
                <a-select-option value="4">
                  分组
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-button
                @click="groupSearchButtons(1)"
                style="margin-right: 15px"
            >重置
            </a-button>
            <a-button
                type="primary"
                @click="groupSearchButtons(2)"
            >查询
            </a-button>
          </a-col>
        </a-row>
      </a-form-model>
      <p style="padding: 10px 0;font-weight: bold">已选择{{ selectedRowKeys.length }}项</p>
      <div class="table-box">
        <a-table
            :columns="columns"
            :data-source="data_list"
            :loading="loading"
            :pagination="false"
            :rowKey="(record) => record.id"
            bordered
            :scroll="{ y: 400,x:800 }"
            :row-selection="rowSelection"
        >
          <div slot="name" slot-scope="text, record">
            <span>{{ record.name || "--" }}</span>
          </div>
        </a-table>
        <!-- 分页配置 -->
        <base-pagination
            :currentPage="paginationProps.current"
            :pageSize="paginationProps.page_size"
            :total="paginationProps.total"
            @onChange="handleTableChange"
            @onShowSizeChange="handleTableChange"
        >
        </base-pagination>
      </div>
    </a-modal>
    <!--    更多-->
    <a-modal v-model="dealerListVisible" :footer="null"
             class="drawsVisible"
             :title="dealerType == 1 ? '品牌banner名称' : dealerType == 2 ? '经销商banner名称' : dealerType == 3 ? '品牌名称' : dealerType == 4 ? '经销商名称' : ''">
      <a-table
          :columns="dealerType == 1 || dealerType == 3 ? dealerColumns : dealerType == 2 || dealerType == 4 ? dealerColumns1 : []"
          :data-source="dealer_list"
          :pagination="false"
          :rowKey="(record, index) => index"
          bordered
      >
        <div slot="num" slot-scope="text, record, index">
          {{ parseInt(index) + 1 }}
        </div>
      </a-table>
    </a-modal>
  </div>
</template>

<script>

import _api from "@/api/contentProduction.js";


export default {
  name: "groupModal",
  data() {
    return {
      dealerColumns: [
        {
          title: "序号",
          align: "center",
          scopedSlots: {customRender: "num"},
          width: 65
        },
        {
          title: "品牌",
          dataIndex: "manufactor_name",
          align: "center"
        }
      ],
      dealerColumns1: [
        {
          title: "序号",
          align: "center",
          scopedSlots: {customRender: "num"},
          width: 65
        },
        {
          title: "经销商",
          dataIndex: "dealer_name",
          align: "center"
        },
        {
          title: "帐号",
          dataIndex: "user_name",
          align: "center"
        },
        {
          title: "使用人",
          dataIndex: "name",
          align: "center"
        }
      ],
      columns: [
        {
          title: "分组",
          scopedSlots: {customRender: "name"},
          align: "center"
        },
        {
          title: "目标可见",
          dataIndex: "open_type",
          // scopedSlots: { customRender: 'open_type' },
          align: "center",
          customRender: (text, row, index) => {
            if(row.open_type == 1) {
              if(row.open_type_info && row.open_type_info.length && row.open_type_info[0] && row.open_type_info[0].manufactor_name) {
                if(row.open_type_info.length == 1) {
                  return <div class="row_div">
                    <span>{ row.open_type_info[0].manufactor_name }</span>
                  </div>;
                } else if(row.open_type_info.length > 1) {
                  return <div class="row_div">
                    <span>{ row.open_type_info[0].manufactor_name }</span>
                    <p on-click={ () => {
                      this.showDealer(3, row.open_type_info);
                    } }>更多 》</p>
                  </div>;
                }
              } else {
                return <div class="row_div"><span>--</span></div>;
              }
            }
            if(row.open_type == 2) {
              if(row.open_type_info && row.open_type_info.length && row.open_type_info[0] && row.open_type_info[0].dealer_name) {
                if(row.open_type_info.length == 1) {
                  return <div class="row_div">
                    <span>{ row.open_type_info[0].dealer_name }</span>
                  </div>;
                } else if(row.open_type_info.length > 1) {
                  return <div class="row_div">
                    <span>{ row.open_type_info[0].dealer_name }</span>
                    <p on-click={ () => {
                      this.showDealer(4, row.open_type_info);
                    } }>更多 》</p>
                  </div>;
                }
              } else {
                return <div class="row_div"><span>--</span></div>;
              }
            }
            if(row.open_type == 2 || row.open_type == 4) {
              if(row.open_type_info && row.open_type_info.length && row.open_type_info[0] && row.open_type_info[0].dealer_name) {
                if(row.open_type_info.length == 1) {
                  return <div class="row_div">
                    <span>{ row.open_type_info[0].dealer_name }</span>
                  </div>;
                } else if(row.open_type_info.length > 1) {
                  return <div class="row_div">
                    <span>{ row.open_type_info[0].dealer_name }</span>
                    <p on-click={ () => {
                      this.showDealer(4, row.open_type_info);
                    } }>更多 》</p>
                  </div>;
                }
              } else {
                return <div class="row_div"><span>--</span></div>;
              }
            }
            if(row.open_type == 0) {
              return <div class="row_div"><span>全部</span></div>;
            }
          }
        },
      ],
      groupModel: {},
      selectedRowKeys: [],
      data_list: [],
      loading: false,
      paginationProps: {
        total: 0,
        page_size: 10,
        current: 1
      },
      dealerListVisible: false,
      dealerType: "",
      dealer_list: []
    };
  },
  computed: {
    rowSelection() {
      const {selectedRowKeys} = this;
      return {
        selectedRowKeys,
        onChange: (selectedRowKeys) => {
          this.selectedRowKeys = selectedRowKeys;
        }
      };
    }
  },
  props: {
    visible: {
      type: Boolean,
    },
    title: {
      type: String,
    },
    keys: {
      type: Array,
      default: () => []
    },
  },
  watch: {
    visible: {
      handler: function(data) {
        if(data) {
          this.getGroupList(1, 10);
        }
      },
      immediate: true
    },
  },
  mounted() {
  },
  methods: {
    getGroupList(current, page_size) {
      this.loading = true;
      this.selectedRowKeys = [];
      let params = {
        current: current,
        page_size: page_size,
        group_name: this.groupModel.group_name || "",
        open_type: this.groupModel.open_type || "",
      };
      _api.getGroupList(params).then((res) => {
        this.data_list = res.list;
        this.paginationProps.total = res.total;
        this.loading = false;
      });
    }, // 创作分组管理列表
    handleTableChange(current, page_size) {
      this.getGroupList(current, page_size);
    },
    handleOk() {
      if(!this.selectedRowKeys) {
        this.$message.error("请先选择分组！");
        return false;
      }
      const params = {
        guideshootTaskIds: this.keys,
        groupIds: this.selectedRowKeys,
      };
      _api.postTaskGroup(params).then((res) => {
        if(res.code == 200) {
          this.selectedRowKeys = [];
          this.groupModel = {};
          this.$message.success("添加分组成功！");
          this.$emit("handleCancel");
        } else {
          this.$message.error(res.message || "添加分组失败！");
        }
      });
    },
    handleCancel() {
      let that = this;
      this.$confirm({
        title: "确定关闭弹窗吗？",
        content: "关闭后已选择的分组将丢失！",
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          that.selectedRowKeys = [];
          that.groupModel = {};
          that.$emit("handleCancel");
        }
      });
    },
    groupSearchButtons(type) {
      this.paginationProps.current = 1;
      this.paginationProps.page_size = 10;
      switch(type) {
        case 1:
          this.groupModel = {};
          this.getGroupList();
          break;
        case 2:
          this.getGroupList();
          break;
      }
    },
    showDealer(type, list) {
      this.dealerType = type;
      this.dealerListVisible = true;
      this.dealer_list = list;
    }, // 更多展示数据
  }
};
</script>

<style scoped lang="less">
.row_div {
  p {
    padding-top: 10px;
    margin-bottom: 0px;
    color: #448ef7;
    cursor: pointer;
  }
}
</style>
