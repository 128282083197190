<template>
  <div>
    <a-row class="top">
      <a-col :span="20">
        <span>{{ title }}</span>
      </a-col>
      <a-col :span="4">
        <a-button
            type="primary"
            @click="SubmitButtons"
            v-if="buttonName && tableName == 'group' || tableName == 'suggestion' && status !== '已完成'"
        >{{ buttonName }}
        </a-button>
        <a-button
            type="primary"
            @click="actionButtons(7)"
            v-if="tableName == 'script' && status !== '已完成'"
        >{{ buttonName }}
        </a-button>
        <!--<v-upload-->
        <!--    v-if="tableName == 'script' && status !== '已完成'"-->
        <!--    accept-list=".xls,.xlsx,.xlm,.xl"-->
        <!--    :is-open-preview="false"-->
        <!--    :suffix-list="['xls','xlsx']"-->
        <!--    :upload-title="'上传脚本'"-->
        <!--    list-type="text"-->
        <!--    :is-form="'script'"-->
        <!--    @handleUrl="handleUrl(arguments, 'script')"-->
        <!--/>-->
        <v-upload
            v-if="tableName == 'product' && status !== '已完成'"
            accept-list=".mp4,.mov,.m4v"
            :is-open-preview="false"
            :suffix-list="['m4v','mov','mp4']"
            :upload-title="'上传成片'"
            list-type="text"
            :is-form="'script'"
            @handleUrl="handleUrl(arguments, 'product')"
        />
      </a-col>
    </a-row>
    <a-table
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :rowKey="(record, index) => index"
        :loading="loading"
        bordered
        :scroll="{ y: 500,x:1000 }"
    >
      <div slot="names" slot-scope="text, record">
        <a-tooltip>
          <template #title>{{ record.script_name }}</template>
          <p class="scriptName" @click="actionButtons(3,record)">{{ record.script_name }}</p>
        </a-tooltip>
      </div>
      <div slot="version" slot-scope="text, record,index">
        <p
            @click="actionButtons(4,record)"
        >
          第{{ listLength - index }}版
          <span v-if="record.isCurrent == 1" style="padding-left: 10px;color: #40a9ff">(当前)</span>
          <span v-if="record.isCurrent == 0" style="padding-left: 30px;color: #40a9ff;cursor: pointer"
                @click="actionButtons(5,record)">设为当前版本</span>
        </p>
      </div>
      <div slot="product_version" slot-scope="text, record,index">
        <p
            style="color: #40a9ff;cursor: pointer"
            @click="actionButtons(6,record)"
        >
          第{{ listLength - index }}版
        </p>
      </div>
      <div slot="attachSize" slot-scope="text, record,index">
        <span>{{ record.size }}</span>
      </div>
      <div slot="isPass" slot-scope="text, record,index">
        <span>{{ record.isPass == 0 ? "不通过" : record.isPass == 1 ? "通过" : "" }}</span>
      </div>
      <div slot="problemType" slot-scope="text, record">
        <span>{{ record.problemType == 0 ? "不通过" : record.problemType == 1 ? "通过" : "" }}</span>
      </div>
      <div slot="operation" slot-scope="text, record">
        <a @click="actionButtons(2,record)" v-if="tableName =='group'">移除</a>
        <a @click="actionButtons(1,record)" v-if="tableName =='test'">编辑</a>
      </div>
    </a-table>

    <!--添加意见-->
    <a-modal v-model="suggestionModal" title="'添加意见" @ok="handleOkSuggestion" width="40%">
      <a-form-model
          ref="formRef"
          :model="suggestionFormModel"
          layout="inline"
      >
        <a-row style="padding-bottom: 12px">
          <a-row style="padding-bottom: 12px">
            <a-col>
              <a-form-model-item label="意见来源">
                <a-input
                    style="width: 400px"
                    placeholder="请输入"
                    v-model="suggestionFormModel.source"/>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-col>
            <a-form-model-item label="意见内容">
              <a-textarea
                  :rows="4"
                  style="width: 400px"
                  placeholder="请输入"
                  v-model="suggestionFormModel.suggestion"/>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-modal>

    <!--查看成片-->
    <preview-modal
        :visible="preview_visible"
        :preview-src="preview_src"
        width="80%"
        @cancel="handlePreviewCancel"
    />

    <!--添加可见分组-->
    <groupModal :title="'添加可见分组'" :visible="templateGroupModal" :keys="keys"
                @handleCancel="handleCancelGroupModal"/>

    <!--编辑测试记录-->
    <a-modal
        width="40%"
        v-model="testModal"
        title="编辑测试记录"
        @ok="handleOkTest"
        @cancel="() => {
          testModal = false;
          testFormModel = {}
        }"
    >
      <a-form-model
          ref="testFormModel"
          :model="testFormModel"
          layout="inline"
          :rules="testRule"
      >
        <a-row style="padding-bottom: 12px">
          <a-col>
            <a-form-model-item label="测试">
              <span>{{ testFormModel.actorName || "" }}</span>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row style="padding-bottom: 12px">
          <a-col>
            <a-form-model-item label="测试时间">
              <span>{{ testFormModel.atime || "" }}</span>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row style="padding-bottom: 12px">
          <a-col>
            <a-form-model-item label="测试结果" prop="isPass">
              <a-select v-model="testFormModel.isPass" style="width: 300px">
                <a-select-option :value="0">不通过</a-select-option>
                <a-select-option :value="1">通过</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row style="padding-bottom: 12px" v-if="testFormModel.isPass == 0">
          <a-col>
            <a-form-model-item label="问题类型">
              <a-input
                  style="width: 300px"
                  placeholder="请输入"
                  v-model="testFormModel.problemType"/>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row style="padding-bottom: 12px" v-if="testFormModel.isPass == 0">
          <a-col>
            <a-form-model-item label="问题描述">
              <a-textarea
                  :rows="4"
                  style="width: 300px"
                  placeholder="请输入"
                  v-model="testFormModel.problemDescription"/>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-modal>

    <!--上传脚本-->
    <a-modal v-model="upload_visible" title="上传脚本" width="60%">
      <a-form-model
          ref="upload_form"
          :model="uploadForm"
          :rules="rules"
          layout="inline"
      >
        <a-row style="padding-bottom: 12px">
          <a-col>
            <a-form-model-item label="上传方式" prop="attachType">
              <a-radio-group v-model="uploadForm.attachType" @change="changeRedio">
                <a-radio :value="1">
                  在线文档
                </a-radio>
                <a-radio :value="2">
                  本地文件
                </a-radio>
              </a-radio-group>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row style="padding-bottom: 12px" v-if="uploadForm.attachType == 2">
          <a-col>
            <a-form-model-item
                label="上传脚本"
                extra="支持扩展名：.pptx .ppt .png .jpg .jpeg .xlsx .xls"
            >
              <vod-base-upload
                  accept-list=".pptx,.ppt,.png,.jpg,.jpeg,.xlsx,.xls"
                  :has-file-list="hasScript"
                  upload-title="上传文件"
                  list-type="text"
                  isNolmal
                  @handlePreview="handlePreviewOpen(arguments)"
                  @handleUrl="handleUrlUpload(arguments, 'script')"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row style="padding-bottom: 12px" v-if="uploadForm.attachType == 1">
          <a-col>
            <a-form-model-item label="脚本名称" prop="attachName" ref="attachName">
              <a-input
                  style="width: 400px"
                  placeholder="请输入"
                  v-model="uploadForm.attachName"/>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row style="padding-bottom: 12px" v-if="uploadForm.attachType == 1">
          <a-col>
            <a-form-model-item label="文档链接" prop="attachUrl" ref="attachUrl">
              <a-input
                  style="width: 400px"
                  placeholder="请输入"
                  v-model="uploadForm.attachUrl"/>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
      <template #footer>
        <a-button @click="closeModal">
          取消
        </a-button>
        <a-button type="primary" :loading="uploadLoading" @click="handleOkUpload">
          确定
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import _api from "@/api/contentProduction.js";
import previewModal from "@/components/PreviewModalVideo/PreviewModalVideo";
import vodBaseUpload from "@/components/VodBaseUpload/vodBaseUpload";
import groupModal from "./groupModal";
import { cloneDeep } from "lodash-es";
import utils from "@/common/util.js";

export default {
  name: "table_detail",
  data() {
    return {
      suggestionFormModel: {},
      suggestionModal: false,
      preview_visible: false,
      preview_src: "",
      templateGroupModal: false,
      testModal: false,
      testFormModel: {},
      testRule: {
        isPass: [
          {required: true, message: "请选择测试结果", trigger: "change"}
        ],
      },
      upload_visible: false,
      uploadForm: {
        attachType: 1
      },
      rules: {
        attachType: [
          {required: true, message: "请选择上传方式", trigger: "change"}
        ],
        attachUrl: [
          {required: true, message: "请输入文档链接", trigger: "change"}
        ],
        attachName: [
          {required: true, message: "请输入脚本名称", trigger: "change"}
        ]
      },
      uploadLoading: false,
      hasScript: [],
      suffix_list: ["pptx", "ppt", "jpg", "jpeg", "xlsx", "xls",],
      thumb_url: "http://afanticar-test.oss-cn-hangzhou.aliyuncs.com/adsys/2020/10/xQVHkI8uIrzoICsANthwzqqhZtAUUUmIqUr0uJiX.jpeg",
      upload_data: {}
    };
  },
  props: {
    title: {
      type: String
    },
    tableName: {
      type: String
    },
    buttonName: {
      type: String
    },
    columns: {
      type: Array,
      required: true,
      default: () => []
    },
    list: {
      type: Array,
      required: true,
      default: () => []
    },
    keys: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
    },
    bizId: {
      type: [String, Number]
    },
    bizType: {
      type: String
    },
    status: {
      type: [String, Number]
    },
    listLength: {
      type: Number
    },
  },
  components: {previewModal, groupModal, vodBaseUpload},
  mounted() {
  },
  methods: {
    SubmitButtons() {
      switch(this.tableName) {
        case "suggestion":
          this.suggestionModal = true;
          this.suggestionFormModel = {};
          break;
        case "group":
          this.templateGroupModal = true;
          break;
      }
    },
    actionButtons(type, data) {
      switch(type) {
        case 1:
          this.testModal = true;
          this.testFormModel = cloneDeep(data);
          break;
        case 2:
          let that = this;
          this.$confirm({
            title: "是否确定移除该可见分组？",
            okText: "确定",
            cancelText: "取消",
            onOk() {
              that.removeGroup(data);
            },
            onCancel() {
              // console.log("取消");
            },
          });
          break;
        case 3:
          console.log(data.attachUrl);
          if(data.attachUrl) {
            let str = data.attachUrl.split("?")[0];
            const isWX = str.split("/")[2];
            const isImg = str.split(".")[3];
            if(isImg && isImg == "webp") {
              this.preview_visible = true;
              this.preview_src = data.attachUrl || "";
            } else if(isWX && isWX == "doc.weixin.qq.com") {
              window.open(data.attachUrl, "_target");
            } else {
              let URL = encodeURIComponent(data.attachUrl);
              window.open(`http://view.officeapps.live.com/op/view.aspx?src=${ URL }`, "_target");
            }
          } else {
            this.$message.error("当前脚本无法查看");
          }
          break;
        case 4:
          break;
        case 5:
          const params = {
            id: data.id,
            isCurrent: 1
          };
          this.saveOrUpdate(params);
          break;
        case 6:
          this.preview_visible = true;
          this.preview_src = data.attachUrl || "";
          break;
        case 7:
          this.upload_visible = true;
          this.uploadForm = {
            attachType: 1
          };
          // this.$set(this.uploadForm, "attachType", 1);
          break;
      }
    },
    saveOrUpdate(params) {
      _api.saveOrUpdate(params).then(res => {
        if(res.code == 200) {
          console.log(res);
          this.$emit("loaded", this.tableName);
          this.closeModal();
        } else {
          this.$message.error(res.message || "上传失败，请刷新重试");
        }
      });
    },//上传脚本/成片
    handleUrl(data, type) {
      console.log(data, type);
      const fileData = data[0];
      const attachName = fileData.name ? fileData.name.split(".")[0] : "";
      let params = {
        id: data.id || "",
        bizId: this.bizId,
        bizType: this.bizType == "script" ? 2 : this.bizType == "product" ? 3 : null,
        attachUrl: fileData.response.url,
        attachName: attachName,
        attachSize: fileData.size,
        creatorName: this.$root.user.realname,
        attachType: this.uploadForm.attachType || 1,
      };
      this.saveOrUpdate(params);
    },
    handleOkSuggestion() {
      const params = {
        bizId: this.bizId,
        bizType: this.bizType == "script" ? 2 : this.bizType == "product" ? 3 : null,
        source: this.suggestionFormModel.source,
        suggestion: this.suggestionFormModel.suggestion,
        creatorName: this.$root.user.realname
      };
      _api.suggestionAdd(params).then(res => {
        if(res.code == 200) {
          this.suggestionModal = false;
          this.$emit("loaded", this.tableName);
        } else {
          this.$message.error(res.message || "意见添加失败");
        }
      });
    }, // 添加意见
    handlePreviewCancel() {
      this.preview_src = "";
      this.preview_visible = false;
    },//关闭成片/脚本预览
    handleCancelGroupModal() {
      this.keys = [];
      this.templateGroupModal = false;
      this.$emit("loaded", "group");
    },// 添加分组
    removeGroup(data) {
      let params = {
        guideshootTaskId: this.keys[0],
        groupId: data.group_id,
      };
      _api.removeGroup(params).then(res => {
        if(res.code == 200) {
          this.$message.success("分组移除成功");
          this.$emit("loaded", "group");
        } else {
          this.$message.error(res.message || "分组移除失败");
        }
      });
    },// 移除分组
    handleOkTest() {
      this.$refs.testFormModel.validate((valid) => {
        if(!valid) {
          return;
        }
        const params = {
          isPass: this.testFormModel.isPass,
          id: this.testFormModel.id,
          problemType: this.testFormModel.isPass == 0 ? this.testFormModel.problemType : "",
          problemDescription: this.testFormModel.isPass == 0 ? this.testFormModel.problemDescription : "",
        };
        _api.updateTest(params).then(res => {
          if(res.code == 200) {
            this.testModal = false;
            this.$message.success("测试记录更新成功");
            this.$emit("loaded", "group");
          } else {
            this.$message.error(res.message || "测试记录更新失败");
          }
        });
      });
    }, // 测试记录
    closeModal() {
      this.upload_visible = false;
      this.uploadLoading = false;
      this.$refs.upload_form.resetFields();
    },
    handleOkUpload() {
      this.$refs.upload_form.validate((valid,) => {
        if(!valid) {
          return;
        }
        if(this.uploadForm.attachType == 2 && !this.uploadForm.link) {
          this.$message.error("请先上传文件");
          return false;
        }
        const attachName = this.upload_data.name ? this.upload_data.name.split(".")[0] : "";
        let params = {
          // id: this.upload_data.response.fileId || "",
          bizId: this.bizId,
          bizType: 2,
          attachUrl: this.uploadForm.attachUrl || this.upload_data.response.url,
          attachName: this.uploadForm.attachName || attachName,
          attachSize: this.upload_data.size,
          attachType: this.uploadForm.attachType || 1,
          creatorName: this.$root.user.realname
        };
        this.saveOrUpdate(params);
      });
    },
    handlePreviewOpen(data) {
      console.log(data);
      return;
      this.preview_visible = true;
      this.preview_src = data[0];
    },   // 放大预览
    hasFileArr(url, type) {
      console.log("想知道这个url", url);
      this.thumb_url = "http://fhl-ai-video-test.oss-cn-hangzhou.aliyuncs.com/image/2021-8-9/doc.png";
      if(url) {
        return [
          {
            uid: "-1",
            name: "脚本文件",
            status: "done",
            url: url,
            thumbUrl: utils.fileSuffixTypeUtil(url, this.suffix_list) ? this.thumb_url : url,
            editor: true
          }
        ];
      } else {
        return [];
      }
    },
    handleUrlUpload(data, type) {
      let res = data[0];
      this.upload_data = data[0];
      let url = ((res || {}).response || {}).url;
      // let file_arr = this.hasFileArr(url, type,);
      switch(type) {
        case "script":
          this.$set(this.uploadForm, "link", url);
          // this.$set(this, "hasScript", file_arr);
          break;
      }
    }, // 脚本上传数据预览
    changeRedio() {
      this.uploadForm.attachUrl = "";
      this.uploadForm.attachName = "";
      this.uploadForm.link = "";
      // this.$refs.upload_form.clearValidate(["attachName", "attachUrl"]);
    }
  }
};
</script>

<style scoped lang="less">
.top {
  padding: 15px 0;

  span {
    font-size: 16px;
    font-weight: bold;
  }
}

.scriptName {
  width: 160px;
  color: #40a9ff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}
</style>
